import React from "react"
import { Link, navigate } from "gatsby"
import "./footer.css"
import styled from "@emotion/styled"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAmilia,
  faFacebookSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faMailBulk } from "@fortawesome/free-solid-svg-icons"
const FooterLink = styled(Link)`
  text-decoration: none;

  position: relative;
  display: block;
  color: #ffffff;
  font-size: 24px;
  font-family: futura-pt;
  height: 40px;
  margin-bottom: 0.8rem;
  display: flex;
  z-index: 200;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer !important;
  &&:hover {
    text-decoration: none;
    color: white;
    opacity: 0.95;
  }
  span {
    width: 0px;
    bottom: 0;
    display: block;
    position: absolute;
    height: 3px;
    background-color: var(--gr-orange);
    transition: all 0.2s;
    bottom: 0px;
  }
  &&:hover > span {
    width: 100px;
  }
`
const MyFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: white;
  margin-right: 1rem;
`
const Footer = () => {
  return (
    <footer>
      <div className="footer--top">
        <div>
          <FooterLink className="footer-link" to="/gift-cards">
            Gift Cards
            <span></span>
          </FooterLink>

          <FooterLink className="footer-link" to="/about">
            About Us
            <span></span>
          </FooterLink>
          <FooterLink className="footer-link" to="/contact-us">
            Contact Us
            <span></span>
          </FooterLink>
          <FooterLink className="footer-link" to="/FAQs">
            FAQs
            <span></span>
          </FooterLink>
        </div>
        <div>
          {/* <h4>How To Project Videos</h4> */}
          <FooterLink className="footer-link" to="/kits/bundles">
            Bundles
            <span></span>
          </FooterLink>
          <FooterLink className="footer-link" to="/kits/event-kits">
            Event Kits
            <span></span>
          </FooterLink>
          <FooterLink className="footer-link" to="/kits/glass-kits">
            Single Projects
            <span></span>
          </FooterLink>
        </div>
        <div className="brazee-link">
          <FooterLink className="footer-link" to="/kits/fundraiser">
            Made Just for You At:
            <span></span>
          </FooterLink>
          <a href="https://brazeestudios.com" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70%"
              height="155.894"
              viewBox="0 0 540.317 155.894"
            >
              <g
                id="Group_11"
                data-name="Group 11"
                transform="translate(-3055.683 -4631.88)"
              >
                <path
                  id="Path_9"
                  data-name="Path 9"
                  d="M3058.869,4632.73a15.718,15.718,0,0,1,10.2,0q3.18.85,3.186,3.611v62.227h.425a29.351,29.351,0,0,1,11.362-12.422,31.638,31.638,0,0,1,16.461-4.354q11.041,0,18.478,4.673a36.108,36.108,0,0,1,11.894,12.1,53.144,53.144,0,0,1,6.372,16.676,93.476,93.476,0,0,1-.106,38.124,45.933,45.933,0,0,1-6.8,16.356,35.371,35.371,0,0,1-12.638,11.254q-7.859,4.251-19.54,4.247a32.5,32.5,0,0,1-14.229-2.866,45.4,45.4,0,0,1-11.682-8.39q0,2.979.106,5.1a6.922,6.922,0,0,1-.531,3.4,4.077,4.077,0,0,1-2.442,1.909,18.23,18.23,0,0,1-5.629.637q-.848,0-2.123-.107a10.622,10.622,0,0,1-2.549-.529,9.228,9.228,0,0,1-2.23-1.063,2.145,2.145,0,0,1-.956-1.911q0-36.526-.105-72.531t-.106-72.53Q3055.683,4633.585,3058.869,4632.73Zm52.779,138.584a21.73,21.73,0,0,0,7.858-7.54,31.833,31.833,0,0,0,4.035-10.831,69.682,69.682,0,0,0,1.168-12.956,99.225,99.225,0,0,0-1.274-14.87,61.318,61.318,0,0,0-4.46-15.5,35.628,35.628,0,0,0-8.708-12.213q-5.527-4.986-14.018-4.99a20.834,20.834,0,0,0-10.937,2.867,26.323,26.323,0,0,0-7.965,7.33,34.169,34.169,0,0,0-4.885,10.086,38.35,38.35,0,0,0-1.7,11.151,67.79,67.79,0,0,0,.532,8.5q.526,4.251.955,8.492a115.336,115.336,0,0,0,1.911,12.641,32.32,32.32,0,0,0,4.143,10.617,21.364,21.364,0,0,0,7.752,7.33q4.884,2.761,12.743,2.758T3111.648,4771.314Z"
                  transform="translate(0 -0.001)"
                  fill="#fff"
                />
                <path
                  id="Path_10"
                  data-name="Path 10"
                  d="M3150.66,4657.022a10.3,10.3,0,0,1,4.357,3.826,5.484,5.484,0,0,1,.743,3.93,3.8,3.8,0,0,1-2.442,2.651,6.256,6.256,0,0,1-4.778-.213,36.179,36.179,0,0,1-5.522-2.336q-3.4-1.695-9.561-1.7-5.73,0-8.174,1.486a6.506,6.506,0,0,0-3.08,4.248,20.8,20.8,0,0,0-.212,6.8,86.823,86.823,0,0,1,.422,8.92,13.06,13.06,0,0,1-.638,4.354,18.418,18.418,0,0,1-1.593,3.4c-.634,1.062-1.271,2.162-1.908,3.288a17.617,17.617,0,0,0-1.594,4.042v44.175q0,3.618-2.229,5.309a8.023,8.023,0,0,1-4.991,1.7,7.446,7.446,0,0,1-4.778-1.8,6.306,6.306,0,0,1-2.23-5.206v-71.579q0-4.881-.216-7.746a7.962,7.962,0,0,0-1.271-4.251,5.068,5.068,0,0,0-3.08-1.8,27.469,27.469,0,0,0-5.416-.425,4.33,4.33,0,0,1-3.508-1.381,4.393,4.393,0,0,1-1.165-2.867,4.658,4.658,0,0,1,1.165-3.079,4.339,4.339,0,0,1,3.508-1.381h24.212v8.286q6.792-4.036,20.39-4.039a24.912,24.912,0,0,1,7.962.957A52.936,52.936,0,0,1,3150.66,4657.022Z"
                  transform="translate(62.613 34.114)"
                  fill="#fff"
                />
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M3170.8,4743.4q-3.615,4.251-10.619,6.905t-19.54,2.651q-11.474,0-18.48-7.009t-7.009-18.054a25.363,25.363,0,0,1,3.185-13.062,31.934,31.934,0,0,1,8.181-9.239,43.18,43.18,0,0,1,11.254-6.156,95,95,0,0,1,12.425-3.716,82.585,82.585,0,0,1,11.681-1.81q5.522-.423,8.924-.634v-10.622q0-5.518-.321-9.768a17.2,17.2,0,0,0-1.909-7.118,10.063,10.063,0,0,0-5.31-4.354,28.66,28.66,0,0,0-10.3-1.486q-6.164,0-8.5,2.128t-2.336,6.372a2.72,2.72,0,0,1-1.7,2.545,9.076,9.076,0,0,1-3.613.956,7.027,7.027,0,0,1-3.61-.744,2.868,2.868,0,0,1-1.7-2.757,29.014,29.014,0,0,1,.638-5.207,15.275,15.275,0,0,1,2.869-6.266,18.667,18.667,0,0,1,6.581-5.2q4.355-2.225,12-2.227,10.614,0,16.566,2.651a19.145,19.145,0,0,1,8.813,7.324,25.781,25.781,0,0,1,3.5,11.048q.633,6.371.635,13.593v66.479q0,1.274-1.909,2.018a12.513,12.513,0,0,1-8.5,0c-1.277-.5-1.911-1.168-1.911-2.018Zm0-35.258c0-1.558-1.065-2.685-3.188-3.4a23.506,23.506,0,0,0-7.859-.956,46.928,46.928,0,0,0-10.193,1.486,40.568,40.568,0,0,0-10.2,4.036,27.525,27.525,0,0,0-7.858,6.586,13.943,13.943,0,0,0-3.187,9.024,12.8,12.8,0,0,0,1.594,5.841,19.534,19.534,0,0,0,4.463,5.627,25.55,25.55,0,0,0,6.688,4.249,20.123,20.123,0,0,0,8.283,1.7,22.7,22.7,0,0,0,9.345-1.8,21.434,21.434,0,0,0,6.8-4.673,17.652,17.652,0,0,0,4.035-6.583,23.719,23.719,0,0,0,1.274-7.75Z"
                  transform="translate(115.959 34.397)"
                  fill="#fff"
                />
                <path
                  id="Path_12"
                  data-name="Path 12"
                  d="M3146.763,4746.025a11.009,11.009,0,0,1,1.908-5.962l54.16-81.321h-49.274a3.962,3.962,0,0,1-3.186-1.245,4.056,4.056,0,0,1-1.06-2.692,4.711,4.711,0,0,1,1.06-3,3.815,3.815,0,0,1,3.186-1.349h70.088q2.975,0,3.292,2.663t-2.014,5.853l-59.048,81.325h40.778c1.7,0,3.187.038,4.461.107s2.41.1,3.4.1a12.953,12.953,0,0,0,3.4-.318,2.255,2.255,0,0,0,1.486-1.274,5.444,5.444,0,0,0,.315-2.548q-.105-1.592-.106-4.143h7.859v16.567h-77.52Q3146.759,4748.793,3146.763,4746.025Z"
                  transform="translate(177.593 36.221)"
                  fill="#fff"
                />
                <path
                  id="Path_13"
                  data-name="Path 13"
                  d="M3198.508,4696.588q-1.066,1.491-2.761,4.036-3.186,8.919-1.169,16.354a27.786,27.786,0,0,0,7.222,12.636,34.6,34.6,0,0,0,12.637,7.965,39.647,39.647,0,0,0,15.08,2.549,15.176,15.176,0,0,1,3.186.85q4.244,1.274,4.248,5.947,0,3.828-2.549,4.565a18.861,18.861,0,0,1-5.31.743q-12.532,0-21.77-4.034a40.475,40.475,0,0,1-15.292-11.363,47.358,47.358,0,0,1-8.921-17.31,78.98,78.98,0,0,1-2.867-21.877,82.754,82.754,0,0,1,2.23-19.857,41.246,41.246,0,0,1,7.221-15.5,32.442,32.442,0,0,1,13.063-9.985q8.065-3.5,19.965-3.5,17.2,0,26.549,10.3t9.345,27.292v5.1h-58.832C3199.637,4693.9,3199.216,4695.6,3198.508,4696.588Zm50.124-26.228a18.129,18.129,0,0,0-6.372-6.9,26.066,26.066,0,0,0-9.664-3.508,74.424,74.424,0,0,0-12.424-.956q-13.173,0-20.071,5.311t-8.6,17.415h59.469Q3250.968,4674.713,3248.632,4670.36Z"
                  transform="translate(242.872 32.991)"
                  fill="#fff"
                />
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M3230.55,4696.588c-.715.994-1.632,2.337-2.764,4.036q-3.187,8.919-1.166,16.354a27.778,27.778,0,0,0,7.222,12.636,34.564,34.564,0,0,0,12.637,7.965,39.632,39.632,0,0,0,15.077,2.549,15.146,15.146,0,0,1,3.186.85q4.248,1.274,4.251,5.947,0,3.828-2.549,4.565a18.876,18.876,0,0,1-5.31.743q-12.536,0-21.771-4.034a40.49,40.49,0,0,1-15.294-11.363,47.3,47.3,0,0,1-8.919-17.31,78.905,78.905,0,0,1-2.869-21.877,82.754,82.754,0,0,1,2.23-19.857,41.335,41.335,0,0,1,7.221-15.5,32.481,32.481,0,0,1,13.063-9.985q8.07-3.5,19.967-3.5,17.2,0,26.549,10.3t9.346,27.292v5.1h-58.835A10.194,10.194,0,0,1,3230.55,4696.588Zm50.124-26.228a18.152,18.152,0,0,0-6.372-6.9,26.081,26.081,0,0,0-9.667-3.508,74.378,74.378,0,0,0-12.425-.956q-13.167,0-20.067,5.311t-8.6,17.415h59.47Q3283.01,4674.713,3280.674,4670.36Z"
                  transform="translate(305.343 32.991)"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
      <div className="footer--bottom">
        <div className="footer--end">
          <MyFontAwesomeIcon
            onClick={() => navigate("https://www.facebook.com/glassroom.us")}
            icon={faFacebookSquare}
          />

          <MyFontAwesomeIcon
            onClick={() =>
              navigate("https://www.instagram.com/glassroomart.us/")
            }
            icon={faInstagramSquare}
          />
          <a href="mailto:info@glassroomart.com" target="_blank">
            <MyFontAwesomeIcon icon={faEnvelope}></MyFontAwesomeIcon>
          </a>
        </div>
        <div className="footer--end terms-section">
          <Link style={{ color: "white" }} to="/terms-and-conditions">
            Terms & Conditions{" "}
          </Link>
          |
          <Link style={{ color: "white" }} to="/privacy-policy">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
