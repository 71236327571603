import { useState } from 'react'
import { mailchimpSubscribe } from '../utils/Fetch/mailchimpSubscribe'
interface HookState {
  loading: boolean
  success: boolean
}
export const useEmailSubscribe = (): [
  (email: string) => void,
  HookState,
  () => void
] => {
  const [apiState, setApiState] = useState<HookState>({
    loading: false,
    success: false,
  })
  const subscribe = async (email: string) => {
    setApiState(prev => ({ ...prev, loading: true }))

    mailchimpSubscribe({ email })
      .then(() => {
        setApiState(prev => ({ ...prev, success: true, loading: false }))
      })
      .catch(err => console.log(err))
    //   .finally(() => setApiState(prev => ({ ...prev, loading: false })))
  }

  const reset = () => {
    setApiState({ loading: false, success: false })
  }

  return [subscribe, { ...apiState }, reset]
}
