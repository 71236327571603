import * as React from 'react'
export const Head = ({ title }: { title: string }) => (
  <>
    <title>{title}</title>
    <meta
      name="facebook-domain-verification"
      content="2chne9ehd3sg184vgznoemp3gurkfi"
    />
  </>
)
