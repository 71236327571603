import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/free-brands-svg-icons'
import SimpleReactLightBox from 'simple-react-lightbox'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './src/api/apollo-client'
import React from 'react'

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={apolloClient}>
      <SimpleReactLightBox>{element}</SimpleReactLightBox>
    </ApolloProvider>
  )
}
