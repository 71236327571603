import React, { useContext, useEffect, useState } from "react"
import Section from "src/components/Section"
import { Spinner } from "react-bootstrap"
import Logo from "src/images/logos/logo-large.png"
import { AppContext } from "src/context/AppContext"
import { CSSTransition } from "react-transition-group"
import LogoSVG from "src/images/logos/logo-svg"
import "./loading.css"
const Loading = props => {
  const {
    appState: { message, loading },
  } = useContext(AppContext)
  const [show, setShow] = useState(true)

  useEffect(() => {
    setShow(loading)
  })
  return (
    <CSSTransition in={show} timeout={2000} classNames="loading">
      <Section>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading-logo">
            <LogoSVG style={{ marginBottom: "3rem" }} />
          </div>
          {/* <p className="loading-p">{message}</p> */}
          <Spinner animation="grow" />
        </div>
      </Section>
    </CSSTransition>
  )
}

export default Loading
