import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const MobileSubNav = ({
  closeMobileMenu,
  links,
  mainLink,
  fixed,
  name,
  toggleMobileMenu,
}) => {
  return (
    <li className={`mobile-nav__sub-menu--item`}>
      <Link to={mainLink} activeClassName="active">
        {name}
      </Link>
      <ul
        className={`mobile-nav__sub-menu--list`}
        style={{ top: `${fixed ? "80px" : "100px"}`, top: "0" }}
      >
        {links.map(link => (
          <Link
            key={link.link}
            onClick={toggleMobileMenu}
            className="mobile-nav__sub-menu--list-item"
            to={link.link}
          >
            {link.name}
          </Link>
        ))}
      </ul>
    </li>
  )
}

MobileSubNav.propTypes = {}

export default MobileSubNav
