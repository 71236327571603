import styled from "@emotion/styled"
import React from "react"

import "./section.css"

const TheSection = styled.section`
  background-color: ${props => props.styleProps.bgc || "white"} !important;
  min-height: ${props => props.styleProps.minHeight || ""} !important;
  padding: ${props => props.styleProps.pd || "0"};
  @media all and (max-width: 765px) {
    padding: 0;
  }
`

const Section = props => {
  return (
    <TheSection
      className={props.className}
      id={props.id}
      styleProps={{ bgc: props.bgc, minHeight: props.minHeight, pd: props.pd }}
    >
      {props.children}
    </TheSection>
  )
}

export default Section
