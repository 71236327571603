import React, { useContext, useEffect } from 'react'
import { AppContext } from 'src/context/AppContext'
import Navigation from 'src/components/Navigation'
import Footer from 'src/components/Footer'
import Section from 'src/components/Section'
import Splash from '../components/Splash/NewSplash'
import { useState } from 'react'
import { GlobalStyle } from '../styles/styles'

import Loading from '../components/Loading'
const Landing = ({ children }) => {
  const [navSmall, changeNavHeight] = useState(false)

  const {
    appState: { loading },
    setLoading,
  } = useContext(AppContext)

  useEffect(() => {
    const load = setTimeout(() => {
      setLoading({ loading: false, message: 'loading' })
    }, 1500)

    return () => clearTimeout(load)
  }, [])

  //for the nav
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > window.innerHeight) {
        changeNavHeight(true)
      } else {
        changeNavHeight(false)
      }
    })
    return () => window.removeEventListener('scroll', this)
  }, [])

  return (
    <>
      <GlobalStyle />
      <Loading></Loading>
      <Section>
        <Splash />
      </Section>
      <Navigation isFixed={navSmall} />
      {children}
      <Footer />
    </>
  )
}

export default Landing
