import React from 'react'
import { css, Global } from '@emotion/core'

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      @import url('https://use.typekit.net/cxy5mnm.css');

      :root {
        --gr-blue: #64c9e3;
        --gr-orange: #ea3626;
        --gr-loading: #64c9e3;
        --gr-grey: #5f5f5f;
        --gr-success: #00d940;
        --gr-error: #ff0e00;
      }
      @keyframes buttonAnimate {
        0% {
          width: 0%;
          height: 0%;
        }
        50% {
          width: 50%;
          height: 100%;
        }
        100% {
          width: 100%;
          height: 100%;
        }
      }
      body,
      html {
        padding: 0;

        box-sizing: border-box;
        font-size: 14px;
        font-family: sans-serif;
        scroll-behavior: smooth;
        /* min-width: 100%; */
        width: 100%;
        hyphens: none;
        max-width: 1920px;
        margin: 0 auto;
      }
      .tl-wrapper-status--entered {
        transform: none !important;
      }
      .bold {
        font-weight: 800;
      }

      .blue {
        color: var(--gr-blue) !important;
      }
      .big-p {
        color: var(---gr-grey);
        font-size: 36px;
      }
      .white {
        color: #ffffff;
      }
      a {
        color: var(--gr-blue);
        text-decoration: none;
      }
      a:hover {
        color: var(---gr-orange);
        text-decoration: none;
      }

      a::visited {
        color: var(--gr-blue);
      }

      strong {
        font-weight: 600;
      }
      button {
        margin-top: 1rem;
        background-color: var(--gr-orange);
        cursor: pointer;
        position: relative;
        transition: all 0.2s;
        font-family: futura-pt;
      }
      button.btn-blue {
        background-color: var(--gr-blue);
      }

      button.read-more {
        height: auto;
        width: auto;
        padding: 10px;
        font-size: inherit;
        margin: 0;
        display: inline-block;
        background: none;
        line-height: 0;
        color: var(--gr-orange);
      }

      button::after {
        width: 0%;
        background-color: rgba(255, 14, 0, 1);
        height: 100%;
        left: 0;

        bottom: 0;
        content: ' ';
        position: absolute;
        transition: all 0.1s;
        border-radius: 50px;
        z-index: -1;
      }

      .btn-blue::after {
        width: 0%;
        background-color: rgba(0, 217, 255, 0.767);
        height: 100%;
        left: 0;
        bottom: 0;
        content: ' ';
        position: absolute;
        color: white;
        transition: all 0.1s;
        border-radius: 50px;
        z-index: -1;
      }

      button:hover {
        opacity: 0.9;
        color: white;
      }
      button:hover::after {
        width: 100%;
        border-radius: 10px;
        height: 100%;
        color: white;
      }

      button:disabled,
      button[disabled] {
        border: none;
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
      }

      button:disabled:hover::after,
      button[disabled]:hover::after {
        background-color: #f7f7f7;
        cursor: not-allowed;
      }

      select {
        min-width: 200px;
        max-width: 500px;
        padding: 5px;
        font-size: 24px;
        font-family: futura-pt;
        color: var(--gr-grey);
      }
      select.gr-select::after {
        content: '<>';
        position: absolute;
        left: 0;
        height: 50px;
        width: 50px;
        background-color: red;
      }

      h1,
      h2 {
        font-size: 4rem;
        color: white;
        font-family: futura-pt;
        font-style: bold;
        font-weight: 600;
        line-height: auto;
        margin: 0;
      }
      .blue {
        color: var(--gr-blue);
      }
      .orange {
        color: var(---gr-orange);
      }

      h3 {
        font-family: futura-pt;
        font-style: regular;
        font-weight: 600;
        color: var(--gr-grey);
      }

      div {
        color: var(--gr-grey);
      }

      .shopify-content ul,
      .shopify-content p,
      .shopify-content span,
      .shopify-content div {
        font-size: 1.85rem;
      }
      @media all and (max-width: 1000px) {
        h1,
        h2,
        h3,
        h4 {
          text-align: center;
          font-size: 3rem;
        }
      }
      p {
        font-family: futura-pt, sans-serif;
        font-weight: 100;
        font-size: 1rem;
      }

      button {
        font-size: 24px;
        color: white;
        border: none;
        width: 200px;
        height: 60px;
        border-radius: 8px;
        background-color: var(--gr-orange);
        cursor: pointer;
        display: block;
      }

      button:focus {
        outline: none !important;
      }

      *:focus {
        outline: none !important;
      }

      .centered {
        margin-left: auto;
        margin-right: auto;
      }

      h4 {
        font-family: futura-pt;
        color: white;
        font-size: 2rem;
      }

      h5 {
        font-family: futura-pt;
        color: var(--gr-grey);
        font-size: 1.5rem;
      }
      @media all and (max-width: 1000px) {
        body {
          overflow-x: hidden;
        }
      }
    `}
  />
)
