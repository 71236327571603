import React from 'react'

import styled from '@emotion/styled'

const Styled__Input = styled.input`
  font-family: futura-pt;
  padding: 10px !important;
  border-radius: 50px;
  border: 1px solid var(--gr-grey);
  /* border: none; */
  font-size: 24px;
`
interface TextField__Props {
  onChange: (e: any) => void
  onFocusOut: () => void
  value: string
  style?: React.CSSProperties | undefined
}

export const TextField = ({
  onChange,
  value,
  style,
  onFocusOut,
}: TextField__Props) => {
  return (
    <Styled__Input
      onBlur={onFocusOut}
      style={style}
      id="name"
      type="text"
      name="name"
      placeholder=""
      value={value}
      onChange={e => onChange(e)}
    />
  )
}
