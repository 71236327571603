import React, { useContext } from 'react'

import reduce from 'lodash/reduce'

import { Link } from 'gatsby'
import StoreContext from 'src/context/StoreContext'
import styled from '@emotion/styled'
import './navigation.css'
import EmailSignupButton from '../EmailSignup/EmailSignupButton'
import NavLogo from 'src/images/logos/nav-logo.png'
import { EmailSignupModal } from '../EmailSignup/EmailSignupModal'
import SubNav from './SubNav'
import MobileNav from './MobileNav'

const LogoLink = styled(Link)`
  height: 80%;
  width: 130px;
  position: relative;
  && > img.nav-logo {
    align-self: center;
    position: absolute;
    top: 10px;
  }
  @media all and (max-width: 1000px) {
    width: 60px;
  }
`
const NavLink = styled(Link)`
  text-decoration: none;
  /* height: 100%; */
  color: var(--gr-grey);
  transition: all 0.2s;
  &&::visited {
    color: var(--gr-grey);
  }
  :last-child {
    color: var(--gr-blue);
  }
  &&:hover {
    color: var(--gr-grey);
    text-decoration: none;
  }
  &&.active {
    color: var(--gr-orange);
  }
  &&.active::after {
    content: ' ';
    border-bottom: 1px solid orange;
    width: 100%;
  }
  .orange {
    color: var(--gr-orange) !important;
  }
`

const useQuantity = () => {
  const {
    store: { checkout, localShippingSelected },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  let total = reduce(items, (acc, item) => acc + item.quantity, 0)
  if (localShippingSelected) total = total - 1
  if (total < 0) total = 0
  return [total !== 0, total]
}

const Navigation = props => {
  const [, quantity] = useQuantity()

  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)

  const {
    store: { adding },
  } = useContext(StoreContext)

  //close the mobile menu when page re renders
  const closeMobileMenu = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false)
    }
  }

  return (
    <>
      <MobileNav quantity={quantity} fixed={props.isFixed} />
      <EmailSignupModal />
      <div className="section__nav-wrap" id="nav-top">
        <nav
          className={`nav--home d-sm-none d-md-block
        ${props.isFixed ? 'fixed-nav' : ''}
       `}
        >
          <ul>
            <LogoLink to="/">
              <img className="nav-logo" src={NavLogo} alt="" />
            </LogoLink>

            <SubNav
              name="Glass Kits"
              mainLink="/kits"
              fixed={props.isFixed}
              key="sub-nav--kits"
              links={[
                { name: 'Glass Kits', link: '/kits/glass-kits' },
                { name: 'Bundles', link: '/kits/bundles' },
                {
                  name: 'Event Kits',
                  link: '/kits/event-kits',
                },
                {
                  name: 'Community Frames',
                  link: '/kits/community-frames',
                },
              ]}
            />

            <SubNav
              name="Events"
              mainLink="/events"
              fixed={props.isFixed}
              key="events"
              links={[
                { name: 'All Events', link: '/events' },
                {
                  name: 'Corporate Events',
                  link: '/events/corporate-events/learn-more',
                },
                {
                  name: 'Field Trips',
                  link: '/events/school-field-trips/learn-more',
                },
                {
                  name: 'Celebration Events',
                  link: '/events/celebrations/learn-more',
                },
              ]}
            />

            <li className="menu-item">
              <NavLink
                to="/glass-classes/class-picker?class=All%20Classes"
                activeClassName="active"
              >
                Glass Classes
              </NavLink>
              <span> </span>
            </li>

            <li className="menu-item">
              <NavLink to="/gift-cards" activeClassName="active">
                Gift Cards
              </NavLink>
              <span> </span>
            </li>
            <div style={{ flexGrow: 1 }}></div>
            <li style={{ margin: '0' }}>
              <EmailSignupButton />

              <span> </span>
            </li>

            <li className={adding ? 'cart adding-to-cart' : 'cart'}>
              <NavLink
                style={{ color: '#64c9e3' }}
                to="/cart"
                activeClassName="active"
              >
                {` Cart (${quantity})`}
              </NavLink>
              <span> </span>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Navigation
