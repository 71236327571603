import React from 'react'
// import SEO, { useSEO } from 'gatsby-plugin-seo'
import ContextProvider from 'src/provider/ContextProvider'
import { GlobalStyle } from '../styles/styles'
import Navigation from 'src/components/Navigation'
import Footer from 'src/components/Footer'
import Brand from 'src/components/Footer/Brand'
import Progress from 'src/components/Progress'
import AppContextProvider from '../context/AppContext'

const RegularLayout = ({ children }) => {
  //for the nav
  const [isFixed, setFixed] = React.useState(false)
  // const { siteUrl } = useSEO()
  React.useEffect(() => {
    const scroller = window.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        setFixed(true)
      } else {
        setFixed(false)
      }
    })
    return () => window.removeEventListener('scroll', scroller)
  }, [])

  return (
    <>
      <ContextProvider>
        <AppContextProvider>
          <GlobalStyle />
          <Progress />
          {/* <SEO
            title="Glassroom | Make Glass, Have Fun"
            description="Glassroom is an art studio and craft site, order online, or come in for a class!"
            pagePath="/"
            schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Online Store",
                "name": "Glassroom Cincinnati",
                "image": "${siteUrl}/img/logo.png"
              }
            }`}
          /> */}
          <Navigation isFixed={isFixed} />
          {children}
          <Footer />
          <Brand />
        </AppContextProvider>
      </ContextProvider>
    </>
  )
}
export default RegularLayout
