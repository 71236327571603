import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import { AppContext } from 'src/context/AppContext'
import './new-splash.css'
import picture from 'src/images/picture-bg.png'

import logo from 'src/images/logos/logo-large@2x.png'
import styled from '@emotion/styled'
import { Col, Container, Row } from 'react-bootstrap'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const SplashLink = styled(Link)`
  color: white;
  text-decoration: none;
`
const LandingSection = styled.div`
  position: absolute;
  background: url(${picture}) center no-repeat;
  background-size: cover;
  transition: all 1s ease;
  opacity: 1;
  height: 110vh;
  width: 110vw;
  z-index: 0;
`

const LandingWhite = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 0;
  width: 50vw;
  background-color: rgba(255, 255, 255, 0.85);
  @media all and (max-width: 768px) {
    width: 100vw;
  }
`
const SplashWrap = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0);
`

const Splash = () => {
  const Page = () => (
    <Container fluid style={{ padding: '0' }}>
      <SplashWrap>
        <LandingSection></LandingSection>
        <LandingWhite> </LandingWhite>
        <Row>
          <Col md={12}>
            <div className="section__landing--wrap">
              <div className="section__landing--logo">
                <img className="header-logo" src={logo} alt="glassroom-logo" />
                <p>experience glass. make art. have fun.</p>
                <h1 className="blue landing-welcome-text">
                  Welcome To Glassroom!
                </h1>
                <AniLink
                  swipe
                  direction="left"
                  to="/glass-classes/class-picker"
                  hex="#64c9e3"
                  duration={0.8}
                >
                  <button className="splash">Classes</button>
                </AniLink>
                <AniLink paintDrip to="/events" hex="#64c9e3" duration={0.5}>
                  <button className="splash">Events</button>
                </AniLink>

                <AniLink
                  swipe
                  direction="left"
                  to="/kits/community-frames"
                  hex="#64c9e3"
                  duration={0.8}
                >
                  <button className="splash blue"> Community Frames</button>
                </AniLink>
              </div>
            </div>
          </Col>
        </Row>
      </SplashWrap>
    </Container>
  )

  return <Page />
}

export default Splash
