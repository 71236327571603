import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { AppContext, AppContextType } from '../../context/AppContext'
import { TextField } from '../common/TextField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEmailSubscribe } from '../../hooks/useEmailSubscribe'

import { PING_FORM_URL, EMAIL_REGEX } from '../../utils/constants'

interface InputState {
  value: string
  valid: boolean
  hasOutFocus: boolean
}

export const matchEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email)
}

export const EmailSignupModal = () => {
  const {
    setEmailSignupModal,
    appState: { emailSignupModalOpen },
  } = useContext(AppContext) as AppContextType

  const [emailInput, setEmailInput] = useState<InputState>({
    value: '',
    valid: false,
    hasOutFocus: false,
  })

  const [subscribe, { loading, success }, reset] = useEmailSubscribe()

  useEffect(() => {
    if (emailSignupModalOpen) {
      fetch(PING_FORM_URL).then(res => res)
    }
  }, [emailSignupModalOpen])

  useEffect(() => {
    if (matchEmail(emailInput.value)) {
      setEmailInput(prev => ({ ...prev, hasOutFocus: true }))
    }
  }, [emailInput.value])

  const handleModalClose = () => {
    reset()
    setEmailSignupModal(false)
  }
  const handleEmailChange = e => {
    const value = e.target.value
    setEmailInput(prev => ({ ...prev, value, valid: matchEmail(value) }))
  }

  const handleSubscribe = () => {
    subscribe(emailInput.value)
  }
  return (
    <>
      <Modal
        show={emailSignupModalOpen}
        onHide={handleModalClose}
        keyboard={false}
      >
        <Modal.Header>
          <div style={{ width: '100%', maxWidth: '350px', margin: 'auto' }}>
            <center>
              {!success ? (
                <p style={{ fontSize: '2rem' }}>Sign up for our Newsletter!</p>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0.5rem auto',
                    gap: '1rem',
                  }}
                >
                  <p
                    style={{
                      fontSize: '2rem',
                      margin: '0',
                      color: 'var(--gr-success)',
                    }}
                  >
                    Success!
                  </p>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: '2rem', color: 'var(--gr-success)' }}
                  />
                </div>
              )}

              <p style={{ marginTop: '2rem' }}>
                Stay connected to learn about new glass workshops, events, and
                more!
              </p>
            </center>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            height: 'inherit',
            paddingBottom: '2em',
            filter: loading ? 'contrast(0.5)' : 'unset',
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '5rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'grid',
                justifyContent: 'center',
                alignItems: 'center',
                gridTemplateColumns: '32px auto 32px',
                gap: '1rem',
              }}
            >
              <div></div>
              <TextField
                onFocusOut={() =>
                  setEmailInput(prev => ({ ...prev, hasOutFocus: true }))
                }
                onChange={handleEmailChange}
                value={emailInput.value}
                style={{
                  textAlign: 'center',
                  fontSize: '1.5rem',
                  minWidth: '350px',
                }}
              />
              {emailInput.hasOutFocus &&
                (emailInput.valid ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: '2rem', color: 'var(--gr-success)' }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ fontSize: '2rem', color: 'var(--gr-error)' }}
                  />
                ))}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '5rem',
              }}
            >
              {success ? (
                <></>
              ) : (
                <button
                  style={{ borderRadius: '12px' }}
                  onClick={handleSubscribe}
                  disabled={!emailInput.valid || loading}
                >
                  {loading ? 'Loading' : 'Submit'}
                </button>
              )}
            </div>
            {success && (
              <button
                style={{ marginTop: '1rem', borderRadius: '12px' }}
                onClick={handleModalClose}
              >
                Close
              </button>
            )}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <button onClick={handleClose}>Close</button>
          <button disabled className="btn-blue">
            Understood
          </button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}
