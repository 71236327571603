import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { SHOPIFY_GQL_API } from '../utils/constants'

const httpLink = new HttpLink({
  uri: `${SHOPIFY_GQL_API}`,
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.ACCESS_TOKEN,
  },
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({ addTypename: false }),
})

export const apolloClient = client
