import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { AppContext, AppContextType } from '../../context/AppContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Styled__Button = styled.button`
  background-color: var(--gr-blue);
  font-family: futura-pt;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1rem;
  padding: 0.55rem 1.5rem;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: unset;
  height: unset;
  margin-top: 0;
  &&:hover::after {
    border-radius: 50px;
    color: white;
  }
`
const EmailSignupButton = () => {
  const { setEmailSignupModal } = useContext(AppContext) as AppContextType

  return (
    <Styled__Button onClick={() => setEmailSignupModal(true)}>
      <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '1.5rem' }} />
      Newsletter
    </Styled__Button>
  )
}

export default EmailSignupButton
