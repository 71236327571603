import React from 'react'
import { useSEO } from 'gatsby-plugin-seo'
import SEO from 'src/components/SEO'
import ContextProvider from 'src/provider/ContextProvider'
import AppContextProvider from 'src/context/AppContext'
import { GlobalStyle } from '../styles/styles'
import Landing from './landingPage'
import RegularLayout from './RegularLayout'
import Progress from 'src/components/Progress'
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css'
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false /* eslint-disable import/first */

export default ({ children, pageContext }) => {
  const { siteUrl } = useSEO()
  if (pageContext.layout === 'landing') {
    return (
      <ContextProvider>
        <SEO
          title="Glassroom | Make Glass, Have Fun"
          description="Glassroom is an art studio and craft site, order online, or come in for a class!"
          pagePath="/"
          schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Online Store",
                "name": "Glassroom Cincinnati",
                "image": "${siteUrl}/img/logo.png"
              }
            }`}
        />
        <AppContextProvider>
          <GlobalStyle />
          <Progress />
          <Landing children={children} />
        </AppContextProvider>
      </ContextProvider>
    )
  }
  return <RegularLayout>{children}</RegularLayout>
}
