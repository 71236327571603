import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import "./load.css"

const Bar = styled.div`
  position: fixed;
  top: 0;
  z-index: 1000;
  animation: load 2s infinite;
  height: 10px;
  width: 1%;
  background-color: var(--gr-loading);
  transition: 0.2s ease;
`
const Progress = props => {
  const [mounted, didMount] = React.useState(false)

  useEffect(() => {
    didMount(true)
  }, [])
  return mounted ? "" : <Bar />
}

Progress.propTypes = {}

export default Progress
