import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {
  faCaretDown,
  faCaretSquareDown,
} from "@fortawesome/free-solid-svg-icons"

const NavLink = styled(Link)`
  text-decoration: none;
  /* height: 100%; */
  color: var(--gr-grey);
  transition: all 0.2s;
  &&::visited {
    color: var(--gr-grey);
  }
  :last-child {
    color: var(--gr-blue);
  }
  &&:hover {
    color: var(--gr-grey);
    text-decoration: none;
  }
  &&.active {
    color: var(--gr-orange);
  }
  &&.active::after {
    content: " ";
    border-bottom: 1px solid orange;
    width: 100%;
  }
  .orange {
    color: var(--gr-orange) !important;
  }
`
const SubNavLink = styled(AniLink)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--gr-orange);
  border-bottom: 1px dotted grey;

  &&:hover {
    color: var(--gr-grey);
    text-decoration: none;
  }
  &&.active {
    color: var(--gr-blue);
  }

  @media all and (max-width: 768px) {
    padding: 0;
    margin: 0;
    border: none;
  }
`

const DownIcon = styled(FontAwesomeIcon)`
  font-size: 26px;
  position: absolute;
  right: -22px;
  margin-top: 3px;
  transition: all 0.2s ease;
  &&.menu-open {
    transform: rotate(180deg);
  }
`

const SubNav = ({
  closeMobileMenu,
  links,
  mainLink,
  fixed,
  name,
  mobileMenuOpen,
}) => {
  const [subMenuOpen, setMenuOpen] = React.useState(false)

  const subMenuRef = React.useRef(null)
  const subMenuRef2 = React.useRef(null)
  useEffect(() => {
    const hoverListener = subMenuRef.current.addEventListener(
      "mouseover",
      () => {
        setMenuOpen(true)
      }
    )
    const outListener = subMenuRef.current.addEventListener(
      "mouseleave",
      () => {
        setMenuOpen(false)
      }
    )
    return () => {
      window.removeEventListener("mouseover", hoverListener)
      window.removeEventListener("mouseover", outListener)
    }
  }, [])
  const handleMenuClose = () => {
    setTimeout(() => setMenuOpen(false), 100)
  }

  return (
    <li className={`menu-item`} ref={subMenuRef}>
      <NavLink to={mainLink} activeClassName="active" onClick={closeMobileMenu}>
        {name}
      </NavLink>
      <DownIcon className={subMenuOpen ? "menu-open" : ""} icon={faCaretDown} />
      <ul
        ref={subMenuRef2}
        className={`sub-menu ${subMenuOpen ? "" : "hidden"}`}
        style={{ top: `${fixed ? "80px" : "200px"}` }}
      >
        {links.map((link, index) => (
          <SubNavLink
            key={`${link.link}--${index}`}
            className="menu-sub-item"
            to={link.link}
            swipe
            direction="left"
            duration={0.5}
          >
            {link.name}
          </SubNavLink>
        ))}
      </ul>

      <span> </span>
    </li>
  )
}

SubNav.propTypes = {}

export default SubNav
