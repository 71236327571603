import { EMAIL_SUBSCRIBE_URL } from '../constants'

// const U_VAL = 'ab665e2fc330e1e640ec451bb'
// const ID_VAL = '4567f9ab98'
// const F_ID_VAL = '00fdc2e1f0'
export async function mailchimpSubscribe(params: { email: string }) {
  return fetch(EMAIL_SUBSCRIBE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: { email: params.email, subscribe: true } }),
  })
}
