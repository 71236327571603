import React from "react"
import PropTypes from "prop-types"

const LogoSVG = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 756.71 636.69"
    >
      <path
        d="M409.12,25.2C435.85,25.08,459.46,28,483,32c45.94,8,89.43,23.5,131.54,43.12,11.88,5.53,21.41,14.67,32,22.23,15.17,10.86,30.14,22,44.22,34.28,16.43,14.32,28,32.43,39.17,50.86,15.76,26,30.72,52.3,40.85,81.08,5.28,15,7.28,30.76,10.35,46.27,5.83,29.51,5.85,59.59,9.19,89.33,2.23,19.86,4.46,39.69,5.47,59.64.45,8.68-.44,17.31-.47,26-.05,17.67-.9,35.3-1.26,52.95-.06,2.88-.31,5.39-4,5.67-3.07.24-4.49-.38-4.09-4.12,3.45-31.68,3.72-63.44,2.2-95.24-1.44-30.27-5.87-60.26-8.57-90.42-2.52-28.13-5.83-56.49-15.07-83.4a402.41,402.41,0,0,0-51.73-100.57C694.5,143.73,670.31,124.2,645,105.91c-11.19-8.09-22-16.75-34.06-23.62-19.41-11-40.56-17.68-61.38-25.23a387,387,0,0,0-89.74-21.28,347.09,347.09,0,0,0-94.05,2c-16,2.59-31.5,6.85-47.34,9.74-9.83,1.8-18.76,5.83-27.24,10.86q-30.31,18-60.42,36.25a104.76,104.76,0,0,0-23.69,19.73c-7.17,8-15.74,14.76-23.72,22-12.87,11.71-23.83,25.05-34.12,39-33.36,45.3-55,96-70.23,149.83-5,17.79-9.07,35.85-13.78,53.7a579.66,579.66,0,0,0-12.54,58.54,280.15,280.15,0,0,0-3.44,41.76C49.17,497.77,47,516.11,46,534.55c-.09,1.71-.19,3.53-2.46,3.67a3.84,3.84,0,0,1-4.3-3.33,11.22,11.22,0,0,1,0-3.47c1.82-16.55,3.07-33.17,3.41-49.79a426.19,426.19,0,0,1,12.47-93.39c6.61-26.82,13-53.7,20.88-80.2a479,479,0,0,1,46-105.58c20.54-35,47.67-64.65,77.15-92.56,26.47-25.06,58.26-41.46,89.09-59.47,22.33-13,47.58-17.83,73-21.06C378.06,27.23,394.87,24.53,409.12,25.2Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M434.14,114.35c-23.37-1.43-45.6,4.92-68,9.83-16.11,3.52-32.23,7.15-47.67,13.08-29.25,11.23-54.95,28.31-79.07,48.09-28.52,23.4-49.72,52.71-68.29,84.09a428.21,428.21,0,0,0-38.21,85.09c-6.07,18.51-12.85,36.81-16.21,56.1-6.89,39.49-15,78.8-19.39,118.69-.15,1.32-.18,2.66-.24,4-.24,4.58-3.39,6.54-7.77,5-1.72-.63-1.68-1.75-1.43-3.18,5.81-33.11,8.42-66.69,15.49-99.65,4.46-20.79,6.93-42,13.57-62.33,12.17-37.3,25.81-74,45.83-107.89,16.3-27.57,35.28-53.36,58.07-76.09,6.73-6.71,14.53-12.21,21.9-18.18,11.64-9.42,23.54-18.39,36.82-25.39,25-13.18,50.89-23.71,78.76-29.37,15.33-3.11,30.43-7.79,46.14-8.31,19.09-.62,38.16-3,57.34-.48,28,3.73,54.39,12.26,80,23.82,21.57,9.72,43.31,19,63.82,30.94a152.27,152.27,0,0,1,25.52,19.19c22.41,20.28,43.3,41.89,59.2,67.91,10.81,17.67,17.7,36.89,23.69,56.52,4.18,13.71,8.57,27.42,11,41.52,4.54,26.42,11.33,52.35,15.42,78.89,3.67,23.79,6.33,47.53,6.28,71.52,0,13.63-.79,27.29-.69,40.95,0,3.4-1.39,4.68-4.73,4.92-3.78.27-4.26-2.19-4.22-4.72.16-10.65,0-21.35,1-31.93.92-9.54,0-19-.47-28.4-.72-15.27-2.36-30.54-4.45-45.69C730.56,413.7,725.6,395,722.05,376a665.71,665.71,0,0,0-21.14-81.68,194.61,194.61,0,0,0-44.45-75c-5.38-5.68-10.48-11.62-15.68-17.46-19.76-22.15-43.86-38-70.78-50.23Q540,137.94,509.25,126C485.23,116.57,459.67,115,434.14,114.35Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M435.86,185.78c-10.16-.45-20.31-.76-30.46-1.13-16.7-.61-33.13,2.57-49.57,5.22-24,3.86-44,16.21-63.16,29.75-22.69,16-41.28,36.53-58.43,58.33-18.62,23.66-33,49.44-42.51,78.14-7,21.19-15.2,42-19.79,63.92-4.48,21.34-10,42.54-13.05,64.09-2.46,17.49-6.59,34.62-9,52.07-.44,3.16-1.49,4.86-5.11,4.28s-4.15-2.89-3.6-5.81c1.24-6.55,2.44-13.1,3.94-19.59,3-13,2.45-26.62,5.83-39.49,7.49-28.52,10.64-58,19.78-86.15,7.87-24.22,15.57-48.56,26.92-71.46A271.63,271.63,0,0,1,240.38,256c15.4-16.93,31.11-33.85,50.73-46.37,15.48-9.87,31.05-19.71,48.51-25.54,14.23-4.75,29.28-6.51,44.19-7.66,16.24-1.25,32.58.33,48.85.35,12,0,23.93-.18,35.82,1.52,20,2.87,40.65,4.51,58.63,13.73,20.94,10.74,42.61,20.6,61,35.88,11.41,9.46,23.57,18,33.05,29.62,6.23,7.63,11.41,15.93,16.66,24.26,6.76,10.71,13.16,21.59,18.82,32.91,6.57,13.13,13,26.5,16.86,40.56,5.64,20.48,10.84,41.14,13.41,62.36,3.38,27.94,6.28,55.86,6.34,84.09,0,11.64-.49,23.26-.37,34.9.06,6.2-1.58,7.46-6.32,6.91-2.37-.27-3.12-1.18-2.81-3.56,3.38-25.59,1.72-51.12-.59-76.69-1.94-21.4-4.28-42.7-8-63.88a294.32,294.32,0,0,0-36-98.36c-10.22-17.44-20.52-34.76-35.6-48.56-19.28-17.65-41.06-31.38-64-44C514.78,195,488.22,190.28,461.3,186,453,184.67,444.35,185.78,435.86,185.78Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M399.68,243.14c26.39.7,48.74,1.38,70.8,4.58,14.69,2.12,29.79,4,42.95,12.19a20.64,20.64,0,0,0,8,2.88c20.42,2.79,36,14.9,52,26.44,25.18,18.29,39,44.6,50.48,72.42,7.83,19,13,38.85,16.69,59.05a8.42,8.42,0,0,1,.31,2c-1,20.73,5.93,40.66,5.68,61.49-.17,14.48,3.18,29.06,3.87,43.71.15,3.31.3,6.63.44,9.94.09,2.07.15,4.19-2.68,4.47-2.65.25-5.24,0-5.73-3.29a86.69,86.69,0,0,1-1.25-11.9c-.2-21.35-3.64-42.43-5.17-63.67-1.22-16.93-2.82-33.83-6.07-50.53-6.33-32.53-17-63.32-36.63-90.47-11.09-15.34-25.5-26.93-41-37.57-20.29-13.93-43.44-20-66.78-25.4-15.89-3.71-32.25-4.74-48.52-5.95-10.45-.78-20.91-1.49-31.38-2-26.14-1.26-49.77,5.6-71.61,20.36-24.71,16.7-45.73,37.1-64.68,59.84-11.47,13.75-16.49,30.76-22.52,47.11-7,19-15,37.8-20.21,57.45q-7.27,27.25-12.48,55c-2.78,14.89-6,29.7-7.23,44.83-.26,3.12-1.39,5.4-5.24,5.2-4.47-.24-3.89-3.39-3.66-6.14.94-11.28,2.21-22.5,4.87-33.55,4-16.5,5.24-33.54,10.14-49.88,2.53-8.46,5.44-16.78,8.14-25.17,6.95-21.54,15.52-42.47,23.2-63.73a143.22,143.22,0,0,1,28.56-47.7C291.26,294.74,311,276,334.76,262,356,249.41,379,242.9,399.68,243.14Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M433.84,329.26c-23.76-.5-46,5.78-66.64,16.78-15.58,8.3-27.93,21-38.81,34.83-21,26.72-34.76,57-43.32,89.8-3.87,14.83-7.69,29.66-10.35,44.76-1,5.55-1.18,11.24-1.73,16.87-.14,1.49-.21,3-.42,4.48-.36,2.42-.95,4.63-4.16,4.53-3.38-.11-3.43-2.46-3.53-4.9a110,110,0,0,1,2.44-29.3c5.61-24.34,10.8-48.89,20-72.13,10.41-26.24,23.37-51.23,43.09-72.05,22.7-24,50.73-37.24,83.19-41.58,31.13-4.15,60.91,1.91,90.22,11.87,36.2,12.3,57.74,39.81,74.7,71.89C589.07,425,592.52,447,595,469.06q2.19,19.35,3.76,38.75c.82,10.2-.76,20.28-.8,30.43a2.75,2.75,0,0,1-2.62,3.07c-1.8.19-3.3-.23-4.16-2a17.4,17.4,0,0,1-1.35-9.3c1.38-17.37-1-34.49-3-51.68-1.92-16.56-3.89-33.1-8.77-49.08-9.76-32-28.18-57.91-55.66-77.08-11.67-8.14-25.53-11.29-38.87-15.36A179,179,0,0,0,433.84,329.26Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M765.43,618.91q-7.35,17.73-14.71,35.46c-.88,2.11-1.46,4.56-4.36,4.92-3.59.46-6.28,0-7.83-4.17-4.32-11.54-9.06-22.92-13.67-34.35-.35-.87-.27-2-1.68-2.54-1.73,11.9-3.59,23.71-5.09,35.57-.53,4.15-2.23,6.06-6.41,5.62a16.68,16.68,0,0,0-2,0c-12.72,0-12.74,0-10.64-12.34,3.31-19.53,6.7-39,9.93-58.59.59-3.56,2.11-5.08,5.87-5.18,14.37-.38,14.39-.5,19.51,12.67,3.24,8.35,6.38,16.74,9.66,25.34,2.37-1,2.49-3,3.11-4.57,3.85-9.4,7.68-18.81,11.31-28.3,1.38-3.6,3.48-5.23,7.45-5.13,17.5.46,13.9-2.54,16.69,14,3.14,18.54,5.72,37.17,8.42,55.78.91,6.23.73,6.25-5.31,6.31-13.63.14-13.73.15-15.65-13.09-1.3-9-2.3-18.11-3.44-27.16Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M542.9,661.51c-21.48,1.15-41.51-14.85-41.86-41.23-.28-21.84,18.55-39,42.12-39s42,17.28,41.81,39.26C584.73,644,567.18,662.61,542.9,661.51Zm20.93-41.26c-.09-10.5-9.64-19.48-20.79-19.56s-21.12,9.48-20.92,20c.24,12.05,9.69,21.69,21.12,21.54C554.94,642.05,563.93,632.46,563.83,620.25Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M641.12,661.51c-23.29,1.17-42-17.73-41.45-41.3.58-22.35,17.94-38.94,41.88-38.93,23.6,0,41.57,17.07,41.52,39.39C683,645.11,666.15,661.52,641.12,661.51Zm20.82-40.74c.06-10.86-9.25-20-20.43-20.14-11-.11-21.14,9.32-21.21,19.74-.07,11.73,9.43,21.71,20.81,21.85C652.73,642.36,661.87,632.94,661.94,620.77Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M99.8,616.42c4.67,0,9.33.12,14,0,3.76-.12,5.33,1.25,5.35,5.22.14,24-16.76,40.53-38.88,40.16-17.77-.29-32.18-10.18-37.53-23.95-6.29-16.14-2-35.52,10.15-47,13-12.22,45.81-15.78,59.65,6.41,2.22,3.56,2.9,5.9-1.86,7.37-1.59.49-3.06,1.31-4.61,1.93-2.78,1.1-5.52,2.84-8.39,3.08-3.64.3-3.84-4-5.86-6.06-7.71-7.75-20.54-6.3-26.57,3.63-5.84,9.63-5.87,19.72,0,29.36,6,9.82,20.18,11.67,27.11,3.84,1.63-1.85,3.75-4.06,2.67-6.56-1-2.33-3.88-1-5.92-1.29-2.86-.44-6.81,1.46-8.35-1.53-1.91-3.68-1.09-8.17-.4-12.26.38-2.26,2.64-2.26,4.47-2.28,5-.06,10,0,15,0Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M523.94,517.91a302.76,302.76,0,0,0-3.76-50.09c-4.73-30.11-20.62-50.58-49.91-61.05-26.44-9.45-51.61-7.12-77.46,2.54-18.07,6.76-30.48,18.15-40.6,34.19-12.43,19.67-16.29,41.18-18.18,63.56-.85,10.11-1.92,20.2-2.87,30.3-.21,2.16-.71,4-3.47,3.86s-2.89-2.31-2.91-4.35c-.2-25.18-1.23-50.78,8.45-74.46,9.4-23,21-45.44,44.2-58.12,18.75-10.27,39.16-14.65,60.63-14.1,26.41.67,49.65,9.87,68.62,27.8,13.31,12.56,19.69,29.52,22.26,47.61a442,442,0,0,1,4.12,55.73c.08,5.31.9,10.58.1,15.93-.41,2.73-.84,4.89-4.13,5.18-3.68.32-4.56-2.09-4.94-4.92C523.2,530.88,525,524.22,523.94,517.91Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M447.86,634.07c0,6.23,0,12.46,0,18.7s-.55,6.69-6.52,6.62c-16.85-.2-14.2,2.4-14.33-14.1-.15-18.49.06-37-.1-55.47,0-4.65,1.4-6.75,6.29-6.5,9.81.5,19.66-.7,29.44.77,11.24,1.69,18.52,8.16,20.12,17.83,1.95,11.75-2.08,20.19-12.63,25.51-3.2,1.62-1.61,2.94-.41,4.51,5.68,7.4,11.41,14.76,17,22.19.94,1.25,2.88,2.46,2.08,4.21s-3,.8-4.54,1.08c-13.31,2.37-23.15-1.55-28.41-14.89-1.53-3.87-4.53-7.17-6.86-10.73Zm0-25.7h0c0,1.66,0,3.32,0,5,0,3,1.58,4.27,4.44,3.73a18.65,18.65,0,0,0,5.67-1.7c4.16-2.33,4.64-6.24,3.47-10.39-1-3.63-6.57-6.43-10.66-5.46-4.7,1.13-2.31,5.1-2.94,7.85A4.76,4.76,0,0,0,447.86,608.37Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M229.9,646.4a21.38,21.38,0,0,1-3.49,0c-7.14-1.22-11.86,1.06-13.35,8.66-.63,3.19-2.8,4.6-6.2,4.42-4.15-.22-8.32-.1-12.48,0-3.09,0-4.27-.84-3-4.17q13.34-34.15,26.46-68.41c.9-2.35,2.36-3.51,4.85-3.44.33,0,.66,0,1,0,21.08.55,15.24-3.78,22.9,15.61,7.08,18,13.7,36.07,20.44,54.15,2.21,5.92,1.93,6.22-4.22,6.27a25.35,25.35,0,0,0-3.49,0c-7.85,1.16-13.73-.57-15.16-9.62-.4-2.47-2.32-3.45-4.81-3.4-3.16.06-6.32,0-9.48,0Zm.84-35.92-1.53-.17c-2,5.75-4,11.49-5.9,17.26-.53,1.63,0,3.23,1.93,3.36a64.94,64.94,0,0,0,9.46,0c2-.16,2.21-1.81,1.68-3.43C234.53,621.8,232.63,616.14,230.74,610.48Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M307.21,661.43A47.65,47.65,0,0,1,284.93,655c-2.73-1.58-3.42-3.42-1.81-6.13.34-.57.59-1.19.92-1.77,1.71-3,2.74-7.09,5.32-8.87,3.5-2.41,5.63,2.71,8.77,3.77,4.15,1.41,8.15,3,12.59,1.56,2.68-.88,4.75-2.43,5.14-5.49s-1.27-5-3.69-6.52c-3-1.91-6.51-2.5-9.84-3.51-14.67-4.42-19-10.29-18-23.44,1.05-13.52,10.77-22.3,24.51-23.07,7.61-.44,14.82,1.57,21.78,4.6,2.81,1.22,3.33,2.93,1.84,5.49-.58,1-.95,2.13-1.51,3.15-1.36,2.48-2.23,5.63-4.29,7.21-2.69,2-4.86-1.77-7.54-2.32-3.28-.67-6.46-1.64-9.76-.16-2.13.94-3.68,2.41-4,4.74s1.33,3.65,3,4.77c3.25,2.14,7,3,10.68,4.1,13.49,4.07,18.11,10,17.91,23.2-.19,12.33-6.68,20.39-19,23.62A34.06,34.06,0,0,1,307.21,661.43Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M376.21,661.41c-4.85-.08-12.43-2.27-19.57-6.19-3-1.64-4.33-3.57-2.05-6.73a17.44,17.44,0,0,0,.9-1.78c1.72-3,2.77-7.22,5.38-8.76,2.93-1.71,5.33,2.89,8.4,3.94,4.12,1.41,8.13,3.18,12.59,1.79,3.08-.95,5.4-2.76,5.62-6.24.2-3.31-1.87-5.31-4.66-6.4-3.55-1.39-7.23-2.5-10.88-3.63-8-2.49-14.85-6.2-16-15.68-1.46-11.78,2.83-21.83,11.72-26.64,11.9-6.44,23.58-3.4,35.1,1.34a2.8,2.8,0,0,1,1.57,4.12c-.62,1.36-1.24,2.73-2,4-1.43,2.62-2.36,6-4.56,7.57-2.54,1.81-4.84-1.94-7.56-2.52-3.12-.67-6.13-1.48-9.28-.13-2.12.91-3.8,2.07-3.92,4.63a5,5,0,0,0,3.16,5.19c4,1.6,8.15,2.85,12.2,4.35,13.89,5.15,18.55,13.64,15.45,28.07C405.24,653.84,394.4,661.37,376.21,661.41Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M384.94,524.7c-.76-15.91,2.69-32.84,8.7-48.53,5.83-15.22,16.14-28.54,35-29.36,15.81-.69,32.1,5.19,41.52,20.84s12.49,33.12,14.67,50.85a33.23,33.23,0,0,1-1.06,11.39,15.51,15.51,0,0,0-.12,7.39c.44,1.73,1.77,4.46-1.57,4.56-2.39.07-5.71.5-6.21-3.39a42.58,42.58,0,0,1-.57-8.91,99.5,99.5,0,0,0-11.75-55.38c-7.4-13.94-23-20.49-38.3-17.45-10.32,2.06-17.57,9.54-21.56,18-8.39,17.79-14.51,36.57-10.13,56.89a16.55,16.55,0,0,1-.2,5.44c-.35,3-1.66,4.49-4.85,4.33s-3.58-2.31-3.56-4.83C385,533.05,384.94,529.55,384.94,524.7Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
      <path
        d="M137.84,621.45c0-10.82.16-21.64-.08-32.46-.1-4.5,1.75-6,5.94-5.65a57.15,57.15,0,0,0,8.48,0c4.3-.32,5.89,1.4,5.84,5.76-.18,15.48-.08,31-.06,46.44,0,6.58.12,6.67,6.71,6.71,4,0,8,0,12,0,1.47,0,3.44-.12,3.84,1.6a32.05,32.05,0,0,1,.05,13.82c-.37,1.75-2.33,1.67-3.81,1.67-11.48,0-23,0-34.45,0-3.61,0-4.5-1.83-4.48-5C137.9,643.42,137.85,632.43,137.84,621.45Z"
        transform="translate(-39.12 -25.09)"
        style={{ fill: "#64c9e3" }}
      />
    </svg>
  )
}

export default LogoSVG
