// const GCP_URL_FUNC = `https://us-central1-glassroom-413805.cloudfunctions.net/glassroom-form-handler`
const GCP_URL_RUN = `https://glassroom-webserver-268883719013.us-east4.run.app`
export const SHOPIFY_GQL_API = `https://make-glass-have-fun.myshopify.com/api/2023-01/graphql.json`

export const FORM_SUBMIT_URL = `${GCP_URL_RUN}/send-form`
export const EMAIL_SUBSCRIBE_URL = `${GCP_URL_RUN}/add-email`
export const PING_FORM_URL = `${GCP_URL_RUN}/ping`
export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
