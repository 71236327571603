import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import NavLogo from 'src/images/logos/nav-logo.png'
import './mobilenav.css'
import './navigation.css'
import MobileSubNav from './MobileSubNav'
import EmailSignupButton from '../EmailSignup/EmailSignupButton'

const LogoLink = styled(Link)`
  img.mobile-nav-logo {
    height: 50px;
    width: auto;
    margin: auto;
    margin-top: 5px;
    transition: all 0.4s ease;
  }

  .mobile-nav-open && > img.mobile-nav-logo {
    /* align-self: center; */
    margin-top: 3rem;
    height: 100px;
  }
`

const MobileNav = ({ quantity, fixed }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  return (
    <>
      <div
        className={`d-block d-md-none mobile-nav--wrap  ${
          mobileMenuOpen ? 'mobile-nav-open' : ''
        }`}
      >
        <nav className="mobile-nav">
          <div
            className={`mobile-nav-span`}
            style={{ position: fixed ? 'fixed' : 'absolute' }}
            onClick={toggleMobileMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className="mobile-nav-menu">
            <LogoLink to="/">
              <img
                className="mobile-nav-logo"
                src={NavLogo}
                alt="logo links to home"
              />
            </LogoLink>

            <li className="mobile-nav__sub-menu--item">
              <Link to="/glass-classes" activeClassName="active">
                Glass Classes
              </Link>
              <span> </span>
            </li>
            {/* <MobileSubNav
              name="Glass Classes"
              mainLink="/glass-classes"
              key="sub-nav--classes"
              toggleMobileMenu={toggleMobileMenu}
              links={
                [
                  {
                    name: 'All Classes',
                    link: '/glass-classes/class-picker?class=All%20Classes',
                  },
                  {
                    name: '101: Beginner',
                    link: '/glass-classes/class-picker?class=101',
                  },
                  {
                    name: '201: Artist',
                    link: '/glass-classes/class-picker?class=201',
                  },
                  {
                    name: '301: Master',
                    link: '/glass-classes/class-picker?class=301',
                  },
                ]
              }
            /> */}
            <MobileSubNav
              name="Glass Events"
              mainLink="/events"
              key="sub-nav--events"
              toggleMobileMenu={toggleMobileMenu}
              links={[
                {
                  name: 'Corporate Events',
                  link: '/events/school-field-trips/learn-more',
                },
                {
                  name: 'Field Trips',
                  link: '/events/corporate-events/learn-more',
                },
                {
                  name: 'Celebrations',
                  link: '/events/celebrations/learn-more',
                },
              ]}
            />

            <MobileSubNav
              name="Glass Kits"
              mainLink="/kits"
              key="sub-nav--kits"
              toggleMobileMenu={toggleMobileMenu}
              links={[
                { name: 'Glass Kits', link: '/kits/glass-kits' },
                { name: 'Bundles', link: '/kits/bundles' },
                { name: 'Event Kits', link: '/kits/event-kits' },
              ]}
            />

            <li className="mobile-nav__sub-menu--item">
              <Link to="/gift-cards" activeClassName="active">
                Gift Cards
              </Link>
              <span> </span>
            </li>
            <li style={{ margin: '0' }}>
              <EmailSignupButton />

              <span> </span>
            </li>
            <li className="mobile-nav__sub-menu--item mobile-menu-cart">
              <Link to="/cart" activeClassName="active">
                {` Cart (${quantity})`}{' '}
              </Link>
              <span> </span>
            </li>
          </ul>
        </nav>
      </div>
      <div className={`d-block d-md-none`} style={{ height: '3rem' }}></div>
    </>
  )
}

MobileNav.propTypes = {}

export default MobileNav
