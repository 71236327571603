import React, { useContext, useEffect, useState } from 'react'

interface AppContextState {
  loading: boolean
  message: string
  lightbox: boolean
  localShipModalOpen: boolean
  emailSignupModalOpen: boolean
}

interface LoadingPayload {
  message: string
  loading: boolean
}
export interface AppContextType {
  appState: AppContextState
  setLoadingMessage: (message: string) => void
  setLoading: (payload: LoadingPayload) => void
  setLocalShippingModal: (value: boolean) => void
  setEmailSignupModal: (value: boolean) => void
}
const initialState = {
  loading: true,
  message: 'Loading...',
  lightbox: true,
  localShipModalOpen: false,
  emailSignupModalOpen: false,
}
export const AppContext = React.createContext<AppContextType | null>(null)

const AppContextProvider = ({ children }: { children: any }) => {
  const [appState, setAppState] = useState<AppContextState>(initialState)

  const setLoading = (payload: LoadingPayload) => {
    if (!payload.message) {
      payload.message = ''
    }

    const state = { ...appState }

    state.loading = payload.loading
    state.message = payload.message

    setAppState(state)
    console.log(appState)
  }

  const setLoadingMessage = (message: string) => {
    setAppState({ ...appState, message: message })
  }

  const setLocalShippingModal = (value: boolean) => {
    setAppState(prev => {
      return { ...prev, localShipModalOpen: value }
    })
  }
  const setEmailSignupModal = (value: boolean) => {
    setAppState(prev => {
      return { ...prev, emailSignupModalOpen: value }
    })
  }

  return (
    <AppContext.Provider
      value={{
        appState,
        setLoadingMessage,
        setLoading,
        setLocalShippingModal,
        setEmailSignupModal,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
